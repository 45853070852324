import React, { useState } from 'react';
import { Shield, FileText, Lock } from 'lucide-react';
import { Helmet } from "react-helmet";

const App = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);


  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex flex-col">
      <Helmet>
        <script
          src="https://getlaunchlist.com/js/widget.js"
          type="text/javascript"
          defer
        />
      </Helmet>
      <nav className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Shield className="text-blue-600 w-8 h-8" />
          <h1 className="text-2xl font-bold text-blue-800">HomeEaze</h1>
        </div>
      </nav>

      <main className="container mx-auto px-4 flex-grow flex items-center">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-blue-900 mb-6">
            Your Home Documents, Perfectly Organised
          </h2>
          <p className="text-xl text-blue-800 mb-8">
            Stop stressing about lost warranties, scattered receipts, and home paperwork chaos. HomeEaze digitises and secures all your critical home documents in one simple platform.
          </p>
          <div className="launchlist-widget" data-key-id="Iy0iZM" data-height="80px"></div>

          <div className="mt-12 grid md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <FileText className="mx-auto text-blue-600 w-12 h-12 mb-4" />
              <h3 className="font-bold mb-2">Centralise Documents</h3>
              <p>Keep all home-related paperwork in one secure digital space.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Lock className="mx-auto text-blue-600 w-12 h-12 mb-4" />
              <h3 className="font-bold mb-2">Complete Security</h3>
              <p>Encryption protects your most important documents.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Shield className="mx-auto text-blue-600 w-12 h-12 mb-4" />
              <h3 className="font-bold mb-2">Quick Access</h3>
              <p>Find any document instantly when you need it most.</p>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-blue-600 text-white py-4 text-center">
        <p>© 2024 HomeEaze. Transforming Home Document Management</p>
      </footer>
    </div>
  );
};

export default App;
